import moment from 'moment';

export default {
  // format日期
  dateFormat(date) {
    return moment(date).format('YYYY/MM/DD');
  },
  dateFormatOther(date) {
    return moment(date).format('YYYY-MM-DD');
  },

  // 获取某月的列表不包括上月和下月
  getMonthListNoOther(date, type) {
    const arr = [];
    const toDay = moment(new Date()).format('YYYY/MM/DD');
    // 当前日期的后14天
    if (type === 'preMonth') {
      const TodayDay = date;
      const d = moment(TodayDay);
      for (let i = 0; i < 14; i++) {
        const nowTimeMoment = d.subtract(1, 'days');
        const today = nowTimeMoment.format('YYYY/MM/DD');
        arr.unshift({
          id: nowTimeMoment.get('date'),
          date: today,
          isToday: toDay === today,
          otherMonth: 'nowMonth',
        });
      }
    } else if (type === 'nextMonth') {
      const TodayDay = date;
      const d = moment(TodayDay);
      for (let i = 0; i < 14; i++) {
        const nowTimeMoment = d.add(1, 'days');
        const today = nowTimeMoment.format('YYYY/MM/DD');
        arr.push({
          id: nowTimeMoment.get('date'),
          date: today,
          isToday: toDay === today,
          otherMonth: 'nowMonth',
        });
      }
    } else {
      console.log(toDay);
      // 默认当前的天为第一周
      // 这周的第几天

      const thisWeeKDay = moment().format('d');
      const TodayDay = date;
      const prevDay = moment(TodayDay);
      const nextDay = moment(TodayDay);
      for (let i = 0; i <= thisWeeKDay; i++) {
        if (i === 0) {
          console.log(prevDay.format('YYYY/MM/DD'));
          arr.unshift({
            id: prevDay.get('date'),
            date: prevDay.format('YYYY/MM/DD'),
            isToday: toDay === prevDay.format('YYYY/MM/DD'),
            otherMonth: 'nowMonth',
          });
        } else {
          const nowTimeMoment = prevDay.subtract(1, 'days');
          const today = nowTimeMoment.format('YYYY/MM/DD');
          arr.unshift({
            id: nowTimeMoment.get('date'),
            date: today,
            isToday: toDay === today,
            otherMonth: 'nowMonth',
          });
        }
      }

      // 当前天，后面的天数
      for (let i = 0; i < 13 - thisWeeKDay; i++) {
        const nowTimeMoment = nextDay.add(1, 'days');
        const today = nowTimeMoment.format('YYYY/MM/DD');
        arr.push({
          id: nowTimeMoment.get('date'),
          date: today,
          isToday: toDay === today,
          otherMonth: 'nowMonth',
        });
      }
    }
    return arr;
  },

  // 获取某月的列表 用于渲染
  getMonthList(date, type) {
    return [...this.getMonthListNoOther(date, type)];
  },
  // 默认是周一开始
  sundayStart: false,
};
