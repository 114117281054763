<template>
  <a-spin :spinning="loading">
    <div class="container">
      <div class="title">我的待办</div>
      <div class="back-log-box">
        <div class="back-log-item back-log-task">
          <div class="block-title">
            <span>待办类型</span>
            <custom-view
              title="待办任务自定义"
              :options="taskOptions"
              :defaultCheckedList="userSetting.showTaskFields"
              :onFinish="handleChangeTaskList"
              :minLength="1"
              :maxLength="10"
            >
              <template v-slot:trigger>
                <img class="more-btn" src="../icons/more.svg" />
              </template>
            </custom-view>
          </div>
          <ul class="task-list todo-list">
            <li v-for="item in userSetting.showTaskFields" :key="item.value" class="task-item">
              <div class="label">
                <div class="icon" v-if="item.value === 'OVERTIME'">
                  <img src="../icons/warn.svg" />
                </div>
                <div class="icon" v-else-if="item.value === 'TODAY_OVERTIME'">
                  <img src="../icons/bell.svg" />
                </div>
                <div class="icon task-icon" v-else>
                  <img src="../icons/task.svg" />
                </div>
                <span class="label-text single-line-hide">{{ item.label }}</span>
              </div>
              <span
                class="value hover-underline primary-color"
                @click="
                  () => {
                    reportE(item.label);
                    handleJump('task', item.value);
                  }
                "
              >
                {{ taskUpcomingCount[item.value] || 0 }}
              </span>
            </li>
          </ul>
        </div>
        <div class="back-log-item back-log-task-student">
          <div class="block-title">
            <span>待办等级</span>
          </div>
          <ul class="task-list task-student-list">
            <li v-for="item in studentStatList" :key="item.label" class="task-item">
              <div class="label">{{ item.label }}</div>
              <div class="value hover-underline" @click="() => handleJump('taskStudent', item.label)">
                <span class="primary-color">{{ item.num || 0 }}</span>
                <span class="warn-color">({{ item.expireNum }})</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import {
  getTaskTypeArr,
  queryStudentColumns,
  setStudentColumns,
  getTaskUpcomingCount,
  getStudentStatistics,
} from '@/api/headTeacher';

import storage from 'store';
import { SubjectEnumText, TaskStudentDepthServiceEnum } from '@/enum/index';
import CustomView from '@/components/CustomView';
import { openNewTab } from '@/utils/util';
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

const EXTRA_OPTIONS = [
  {
    label: '已逾期',
    value: 'OVERTIME',
  },
  {
    label: '未来24H',
    value: 'TODAY_OVERTIME',
  },
];

const INITIAL_CHECKED_LIST = [
  ...EXTRA_OPTIONS,
  {
    label: '新生首通',
    value: 'TASK_FIRST_PASS',
  },
  {
    label: '新生跟进',
    value: 'NEW_STUDENT_FOLLOW_UP',
  },
  {
    label: '新生补升',
    value: 'TASK_PROMOTE',
  },
  {
    label: '不续费',
    value: 'TASK_NOT_RENEW',
  },
  {
    label: '学员缺勤',
    value: 'TASK_STUDENT_ABSENT',
  },
  {
    label: '老师迟到',
    value: 'TEACHER_LATE',
  },
  {
    label: '即将过期课时提醒',
    value: 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER',
  },
  {
    label: '订单寄送确认',
    value: 'TASK_CONFIRM_ORDER',
  },
];

export default {
  data() {
    return {
      userInfo: storage.get('userInfo'),
      taskOptions: [],
      loading: false,
      taskUpcomingCount: {},
      userSetting: { showTaskFields: INITIAL_CHECKED_LIST },
      studentStatList: [],
    };
  },
  computed: {},
  components: { CustomView },
  async created() {
    this.loading = true;
    try {
      await Promise.all([
        // 任务类型
        this.getTaskTypeArr(),
        // 初始化选中的待办任务
        this.initChecked(),
        // 服务学员
        this.handleGetStudentStat(),
        // 值对象
        this.handleGetValues(),
      ]);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    reportE(button) {
      reportEvent({
        data: {
          _event: 'CAstudio_HomeTDClick',
          User_id: getUserUUID(),
          button_name: button,
        },
      });
    },
    handleJump(type, value) {
      if (!this.userInfo.subject) {
        this.$message.error('账号未设置科目，请前往设置页进行设置');
        return;
      }

      switch (type) {
        case 'task':
          openNewTab('/mainHome?formHomeType=' + value);
          break;
        case 'student':
          openNewTab('/myStudent');
          break;
        case 'taskStudent': {
          const { href } = this.$router.resolve({
            path: '/app-react/task-student',
            query: {
              depthService: value,
            },
          });
          openNewTab(href);
        }

        default:
          break;
      }
    },

    async getTaskTypeArr() {
      const subjects = this.userInfo.subject ? `【${SubjectEnumText[this.userInfo.subject]}】` : '';
      const { data } = await getTaskTypeArr({ subjects });

      this.taskOptions = data.content.map((item) => ({
        label: item.label,
        value: item.value,
      }));
      this.taskOptions.unshift(...EXTRA_OPTIONS);
    },
    async initChecked() {
      const { data: setting } = await queryStudentColumns();
      setting.content ||= {};
      // 涉及到其他地方的设置，一起存起来，保存的时候提交
      setting.content.showTaskFields ||= INITIAL_CHECKED_LIST;
      this.userSetting = setting.content;
    },

    async handleGetValues() {
      const { data: taskCount } = await getTaskUpcomingCount(this.userInfo.subject);
      // 转键值对好取些
      this.taskUpcomingCount = taskCount.content.reduce((p, c) => {
        return { ...p, [c.value]: c.num };
      }, {});
    },

    async handleGetStudentStat() {
      const { data } = await getStudentStatistics();

      this.studentStatList = Object.values(TaskStudentDepthServiceEnum).map((val) => {
        const target = data.content.find((item) => item.name === val);
        return {
          label: val,
          num: target?.num ?? 0,
          expireNum: target?.expireNum ?? 0,
        };
      });
    },

    async handleChangeTaskList(value) {
      await setStudentColumns({ ...this.userSetting, showTaskFields: value });
      await this.initChecked();
      return true;
    },
  },
};
</script>

<style scoped lang="less">
/deep/.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
.container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #1a2633;
  }

  .back-log-box {
    display: flex;
    gap: 20px;
    height: 100%;
    .back-log-item {
      flex: 1;
      border: 1px solid rgba(31, 45, 61, 0.1);
      border-radius: 8px;
      padding: 12px 20px 20px;
      display: flex;
      display: flex;
      flex-direction: column;
      &.back-log-task {
        flex: 4;
      }

      &.back-log-task-student {
        flex: 1;
        justify-content: space-between;
      }

      .block-title {
        color: #1a2633cc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.more-btn {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
}

.task-list {
  display: flex;
  padding: 0;
  flex: 1;
  &.todo-list {
    flex-wrap: wrap;
    .task-item {
      width: 50%;
      height: 20%;
      &:nth-child(2n) {
        padding-left: 32px;
      }
      &:nth-child(2n-1) {
        padding-right: 32px;
      }
      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }
  }

  &.task-student-list {
    flex-direction: column;
    .task-item {
      height: 20%;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(31, 45, 61, 0.05);
    gap: 8px;
    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      flex: 1;
      gap: 8px;
      .icon {
        width: 22px;
        height: 22px;
        background: #f5f5fc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.task-icon {
          > img {
            width: 8px;
            height: 8px;
          }
        }
        > img {
          width: 12px;
          height: 12px;
        }
      }
      .label-text {
        flex: 1;
        width: 0;
      }
    }
    .value {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.percent {
  font-weight: 600;
  font-size: 20px;
  color: #191c42;
}
.student-count {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0;
  padding: 0;
  background: #f7f8fa;
  position: relative;
  border-radius: 4px;
  &::before {
    content: '';
    width: 1px;
    height: 24px;
    background: #d6d8db;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .student-count-item {
    flex: 1;
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .student-title {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
    }

    .student-count-value {
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
