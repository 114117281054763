import axios, { post, fetch, imgpost } from '@/api';
const BASE_URL = process.env.VUE_APP_API_IM_URL;

// 获取日期区域标记
export const getTodoDateListApi = (params) => post(BASE_URL, '/api/account/calendar/date', params);

// 获取当前日期下的列表
export const getListByDateApi = (params) => post(BASE_URL, '/api/account/calendar/page', params);

// 获取首页作业数量
export const getIndexHomeworkApi = () => {
    return fetch(BASE_URL, '/api/student/homework/count');
}