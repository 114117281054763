<template>
  <div class="birthday-item" @click="toStudentDetail(data)">
    <div class="info-box">
   
        <div class="title-wrap">
           <h4 class="title">{{ data.fullName }}({{ data.code }})</h4>
            <a-icon type="right" style="font-size: 12px; color: #333" />

      </div>
      <div class="info">
        <span class="name">生日：{{ birthday }}</span>
        <span class="time">当前年龄：{{ age }}岁</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    birthday() {
      return moment(this.data.dateOfBirth).format('YYYY.MM.DD');
    },
    age() {
      const birthDayTime = new Date(this.data.dateOfBirth).getTime();
      const nowTime = new Date().getTime();
      return Math.floor((nowTime - birthDayTime) / 31536000000);
    },
  },
  methods:{
     toStudentDetail(record) {
         this.$router.push({
                    path: '/studentDetail',
                    query: {
                      uuid: record.uuid,
                    },
                  });
    }
  }
};
</script>

<style lang="less" scoped>
.birthday-item {
  width: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    &:hover{
    background: rgba(4, 203, 148, 0.06);
  }
  .info-box {
    flex: 1;
    width: 0;
    .title {
      font-size: 14px;
      color: #1f2d3d;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info {
      font-weight: 400;
      font-size: 12px;
      color: rgba(31, 45, 61, 0.8);
    }
    .time {
      margin-left: 12px;
    }
  }
}
.title-wrap{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
</style>
