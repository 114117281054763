import { getUserUUID } from './user';

/**
 * 上报埋点事件，uid 已默认赋值，无需传递，再次传递 uid 可以覆盖默认值
 */
const reportEvent = (data) => {
  if (data) {
    const { uid, ...restParams } = data;
    const effectUid = uid ?? getUserUUID();
    if (effectUid) {
      window?.gatherer?.traceEvent({ uid: effectUid, ...restParams });
    } else {
      throw new Error('uid 获取失败，请检查，或者手动传入 uid');
    }
  }
};

export { reportEvent };
