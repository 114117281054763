<template>
  <div class="class-item" @click="hrefClass(data)">
    <div class="info-box">
      <div class="title-wrap">
        <div class="title-wrapH">
          <h4>{{ data.studentName || '-' }}</h4>
          <div style="margin-left: 5px" v-if="data.firstCourseSchedule">
            <a-tag color="orange"> 学员{{ getDdlLabel(subjectArr, data.subject) }}首课 </a-tag>
          </div>
          <div style="margin-left: 5px">
            <a-tag color="green">
              {{ getDdlLabel(platformsArr, data.platform) }}
            </a-tag>
          </div>
        </div>

        <div class="status">
          <span>{{ status }}</span>
          <a-icon type="right" style="font-size: 12px; color: #333" />
        </div>
      </div>
      <div class="info">
        <span class="name">老师：{{ data.teacherName }}</span>
        <span class="time">课节：{{ date }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getDdlLabel } from '@/utils/util';

const session = storage.createStore(sessionStorage);

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      getDdlLabel,
      platformsArr: [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
    };
  },
  computed: {
    status() {
      const statusEnum = {
        NORMAL: '待开课',
        COMPLETED: '已完成',
        CANCELLED: '已取消',
        ABSENT: '旷课',
      };

      return statusEnum[this.data.status];
    },

    date() {
      const { startDateTime, endDateTime } = this.data;
      console.log(startDateTime);

      const s = new Date(startDateTime || '');
      const e = new Date(endDateTime || '');

      const isSameDay = e.getTime() - s.getTime() < 24 * 60 * 60 * 1000 && e.getDate() === s.getDate();
      console.log(isSameDay);
      if (isSameDay) {
        return `${moment(startDateTime).format('MM.DD HH:mm')}-${moment(endDateTime).format('HH:mm')}`;
      }

      return `${moment(startDateTime).format('MM.DD HH:mm')}-${moment(endDateTime).format('MM.DD HH:mm')}`;
    },
  },
  methods: {
    hrefClass(data) {
      // 跳转课表详情
      const conversation_student = {
        fullName: data.studentName,
        code: data.studentCode,
        uuid: data.studentId,
        timezoneStd: data.timezoneStd,
        timezone: data.timezone,
        classAdmin: data.classAdmin,
      };
      // 记录选中学生信息
      session.set('studentProfile', conversation_student);
      this.$router.push({ path: '/calendar', query: { uuid: conversation_student.uuid } });
      // this.$router.push({ name: 'ClassCalendarAdmin', params: { date: this.data.startDateTime } });
    },
  },
};
</script>

<style lang="less" scoped>
.class-item {
  width: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  &:hover {
    background: rgba(4, 203, 148, 0.06);
  }
  .info-box {
    flex: 1;
    width: 0;
    .title {
      font-size: 14px;
      color: #1f2d3d;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      color: rgba(31, 45, 61, 0.8);
    }
    .time {
      margin-left: 12px;
    }
    .status {
      display: flex;
      align-items: center;
      font-size: 12px;
      > span {
        margin-right: 4px;
      }
    }
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-wrapH {
  flex: 1;
  display: flex;
}
</style>
