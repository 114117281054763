<template>
  <a-row class="home-index-wrapper" :gutter="16">
    <a-col :span="17" class="h-full">
      <div class="flex flex-col h-full">
        <div class="guide-wrap">
          <div class="guide-name">欢迎回来，{{ userInfo.fullName }}</div>
          <div class="guide-txt">
            果实的事业是尊贵的，花的事业是甜美的；但是让我做叶的事业吧，叶是谦逊地、专心地垂着绿荫的。
          </div>
          <div class="home-bg">
            <img src="@/assets/homeBg.png" />
          </div>
        </div>
        <div class="bg back-log">
          <back-log />
        </div>
        <div class="bg data-report">
          <data-report />
        </div>
      </div>
    </a-col>
    <a-col :span="7" class="h-full flex flex-col">
      <div class="quick-wrap">
        <div class="quick-txt">快捷入口</div>
        <HomeWorkReport />
      </div>
      <div class="bg flex-1">
        <todo-list />
      </div>
    </a-col>
  </a-row>
</template>
<script>
import storage from 'store';
import TodoList from './components/TodoList';
import BackLog from './components/BackLog';
import DataReport from './components/DataReport';
import HomeWorkReport from './components/HomeWorkReport.vue';

export default {
  data() {
    return {
      userInfo: {},
    };
  },

  components: {
    TodoList,
    BackLog,
    DataReport,
    HomeWorkReport
  },
  methods: {
    hrefHomeWorkList() {
      this.$router.push({ path: '/homeIndex/homeWorkList' });
    },
  },
  mounted() {
    this.userInfo = storage.get('userInfo');
  },
};
</script>
<style lang="less" scoped>
.back-log {
  margin-bottom: 16px;
  flex: 11;
}
.data-report {
  flex: 9;
}

.home-index-wrapper {
  height: 100%;
  .bg {
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
  }
}

.pop-content {
  width: 100%;

  padding: 8px 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  color: rgba(31, 45, 61, 1);
  > span {
    width: 140px;
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
.pop-label {
  color: rgba(31, 45, 61, 0.6);
}
.pop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.pop-left {
  font-size: 16px;
  color: rgba(31, 45, 61, 0.8);
}
.pop-right {
  font-size: 20px;
  color: #1f2d3d;
  font-weight: bold;
}
.guide-wrap {
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(193, 200, 204, 0.2);
  border-radius: 4px;
  padding: 16px 20px;
  margin-bottom: 16px;
}
.guide-name {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.guide-txt {
  color: rgba(31, 45, 61, 0.8);
  font-size: 12px;
}
.empty-wrap {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 100px;
}
.empty-pic {
  width: 140px;
  height: 140px;
  img {
    width: 100%;
    height: 100%;
  }
}
.empty-txt {
  color: #c0ccda;
  text-align: center;
}
.home-bg {
  width: 316px;
  height: 86px;
  background-size: 100%;
  position: absolute;
  top: 8px;
  right: -5px;
  img {
    width: 100%;
    height: 100%;
  }
}
.quick-wrap {
  padding: 16px 20px;
  background-color: #fff;
  margin-bottom: 16px;
  box-shadow: 0px 2px 4px rgb(193 200 204 / 20%);
  border-radius: 4px;
}
.quick-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
}
.quick-btn:hover {
  border: 1px solid #00bf8a;
  color: #00bf8a;
}
.quick-txt {
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: bold;
}
</style>
