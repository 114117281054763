<template>
  <div>
    <div @click="showModal">
      <slot name="trigger" @click="showModal" />
    </div>
    <a-modal
      width="440px"
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :okButtonProps="{ props: { disabled } }"
    >
      <slot name="extraTitle" />
      <a-form :form="form">
        <a-form-item v-for="k in form.getFieldValue('keys')" :key="k" :required="false">
          <div class="flex items-center justify-between">
            <a-select
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              v-decorator="[`names[${k}]`]"
              placeholder="请选择"
              style="width: 360px"
              :allowClear="true"
            >
              <a-select-option
                :value="item.value"
                v-for="item in options"
                :disabled="(form.getFieldValue('names') || []).includes(item.value)"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-tooltip>
              <template slot="title"> 删除此行 </template>
              <a-icon
                style="font-size: 16px"
                type="delete"
                class="dynamic-delete-button"
                v-show="form.getFieldValue('keys').length > minLength"
                @click="() => remove(k)"
              />
            </a-tooltip>
          </div>
        </a-form-item>
        <a-form-item v-if="form.getFieldValue('keys').length < maxLength">
          <a-button type="dashed" style="width: 360px" @click="add">
            <a-icon type="plus" />
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
let id = 0;
export default {
  name: 'taskStudent',
  data() {
    return {
      visible: false,
      confirmLoading: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 0,
    },
    maxLength: {
      type: Number,
      default: Infinity,
    },
    defaultCheckedList: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    onFinish: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 选中的变了之后触发form更新
    defaultCheckedList: {
      handler() {
        this.initForm();
      },
      immediate: true,
    },
    // 在弹出打开时操作了一堆，但没点确定又直接关了，所以重新打开也需要初始化一次
    visible() {
      this.initForm();
    },
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    initForm() {
      this.form.resetFields();
      const keys = this.defaultCheckedList.map((_, idx) => idx);
      id = keys.length;
      this.form.setFieldsValue({ keys });
      this.defaultCheckedList.forEach((item, idx) => {
        this.form.getFieldDecorator(`names[${idx}]`, { initialValue: item.value, preserve: true });
      });
    },

    showModal() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },

    remove(k) {
      const keys = this.form.getFieldValue('keys');
      this.form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      });
      this.form.getFieldDecorator(`names[${k}]`, { initialValue: undefined });
    },

    add() {
      const keys = this.form.getFieldValue('keys');
      const currId = id++;

      this.form.setFieldsValue({
        keys: keys.concat(currId),
      });

      this.form.getFieldDecorator(`names[${currId}]`, { initialValue: undefined });
    },
    async handleOk() {
      this.confirmLoading = true;
      const { keys = [], names = [] } = this.form.getFieldsValue();
      const values = keys.map((key) => names[key]).filter((_) => _);

      const list = values.map((v) => {
        return this.options.find((o) => o.value === v);
      });

      const result = await this.onFinish(list);
      this.confirmLoading = false;
      if (result) {
        this.visible = false;
      }
    },
  },
};
</script>
