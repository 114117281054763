<template>
  <div
    class="list-scroll"
    v-infinite-scroll="handleInfiniteOnLoad"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-distance="infiniteScrollDistance"
  >
    <a-list :data-source="dataSource.data">
      <a-list-item slot="renderItem" slot-scope="item">
        <slot name="renderItem" :data="item" />
      </a-list-item>
      <p class="lower-text" v-if="loading && !busy && dataSource.total">
        <a-icon type="loading" />
        <span>加载中</span>
      </p>
      <p class="lower-text" v-if="busy && dataSource.total">已经到底了...</p>
    </a-list>
  </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';

export default {
  directives: { infiniteScroll },
  props: {
    dataSource: {
      type: Object,
      default: () => ({
        data: [],
        total: 0,
      }),
    },
    infiniteScrollDistance: {
      type: Number,
      default: 50,
    },
    onLower: {
      type: Function,
      default: async () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    busy() {
      return this.dataSource.data?.length >= this.dataSource.total && this.dataSource.data?.length !== 0;
    },
  },
  data() {
    return {};
  },
  methods: {
    handleInfiniteOnLoad() {
      if (!this.dataSource.total || this.loading || this.busy) return;
      this.onLower();
    },
  },
};
</script>

<style lang="less" scoped>
.list-scroll {
  overflow: auto;
  height: 100%;
  
}
.ant-list-item {
  border-bottom: none;
  padding: 5px 0;
}
.lower-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
}
</style>
