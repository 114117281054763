<style scoped>
@media screen and (min-width: 460px) {
  .wh_item_date:hover {
    background: #04cb94;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
  }
}
* {
  margin: 0;
  padding: 0;
}

.wh_container {
  /* max-width: 410px; */
  margin: auto;
}

.operation-box {
  display: flex;
  align-items: center;
}

.wh_top_changge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC-Medium, PingFang SC;
}
.arrow-box {
  width: 24px;
  height: 24px;
  background: #f3f4f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 4px;
}

.wh_top_changge .wh_content_li {
  font-size: 14px;
  /* font-family: PingFangSC-Medium, PingFang SC; */
  font-weight:bold;
  color: #1d2129;
  cursor: auto;
}
.wh_top_changge .showNowMonth {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  cursor: auto;
}
.wh_top_changge .wh_nowMonth {
  width: 48px;
  height: 24px;
  line-height: 24px;
  background: #f3f4f5;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: center;
  cursor: pointer;
}

.wh_content_all {
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma,
    Simsun, sans-serif;
  /* background-color: #0fc37c; */
  width: 100%;
  overflow: hidden;
  padding-bottom: 8px;
}

.wh_content {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0 3% 0 3%; */
  width: 100%;
}

.wh_content:first-child .wh_content_item_tag,
.wh_content:first-child .wh_content_item {
  color: #ddd;
  font-size: 16px;
}

.wh_content_item,
.wh_content_item_tag {
  width: 14.2%;
  text-align: center;
  position: relative;
}
.wh_content_item {
  height: 40px;
  margin: 5px 0;
}

.wh_top_tag {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  color: #999999;
}

.wh_item_date {
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f2d3d;
}

.wh_jiantou1 {
  width: 6px;
  height: 6px;
  border-top: 1.5px solid #333333;
  border-left: 1.5px solid #333333;
  transform: rotate(-45deg);
}

.arrow-box:active .wh_jiantou1,
.arrow-box:active .wh_jiantou2 {
  border-color: #ddd;
}

.wh_jiantou2 {
  width: 6px;
  height: 6px;
  border-top: 1.5px solid #333333;
  border-right: 1.5px solid #333333;
  transform: rotate(45deg);
  /* margin-right: 10px; */
}
.wh_content_item > .wh_isMark::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #778ba2;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}
.wh_content_item .wh_other_dayhide {
  color: #bfbfbf;
}
.wh_content_item .wh_want_dayhide {
  color: #bfbfbf;
}

.wh_content_item .wh_chose_day {
  width: 32px;
  height: 32px;
  background: #04cb94;
  box-shadow: 0px 2px 6px rgba(255, 112, 112, 0.2);

  margin: auto;
  border-radius: 100px;
}

.wh_content_item .wh_chose_day .wh_item_date {
  color: #fff;
}

.setbot {
  width: 32px;
  height: 6px;
  margin: 5px auto 0;
  display: flex;
  justify-content: space-around;
}
/* .wh_content_item .wh_isToday .wh_item_date::after {
  display: inline-block;
  content: '今';
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #04CB94;
  font-size: 18px;
  box-shadow: 0px 2px 6px 0px rgba(255, 112, 112, 0.2);

  color: #fff;
  margin: auto;
  border-radius: 100px;
  position: absolute; */
/* top: -32px; */
/* top: -42px; */
/* } */

.wh_container .mark1 {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff6a6a;
}
.wh_container .mark2 {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #d1d1d1;
}
.wh_content_item .wh_isNoToday .wh_item_date::after {
  line-height: 28px;
  background: #fff;
  border: 2px solid #04cb94;
  color: #04cb94;
}
</style>
<template>
  <section class="wh_container">
    <div class="wh_content_all">
      <div class="wh_top_changge">
        <div class="wh_content_li" v-if="list.length > 0">
          {{ dateArea }}
        </div>
        <div class="operation-box">
          <div class="arrow-box" @click="PreMonth(myDate, false)">
            <div class="wh_jiantou1"></div>
          </div>
          <div class="arrow-box" @click="NextMonth(myDate, false)">
            <div class="wh_jiantou2"></div>
          </div>
          <div class="wh_nowMonth" @click="toNowMonth">今日</div>
        </div>
      </div>
      <slot name="status"></slot>
      <div class="wh_content">
        <div class="wh_content_item" v-for="item in textTop" :key="item">
          <div class="wh_top_tag">{{ item }}</div>
        </div>
      </div>
      <div class="wh_content">
        <div class="wh_content_item" v-for="(item, index) in list" @click="clickDay(item)" :key="index">
          <div
            :class="[
              { wh_isNoToday: taday !== item.date },
              { wh_isMark: item.isMark },
              { wh_other_dayhide: item.otherMonth !== 'nowMonth' },
              { wh_want_dayhide: item.dayHide },
              { wh_isToday: item.isToday },
              { wh_chose_day: item.chooseDay },
            ]"
          >
            <div class="wh_item_date">{{ item.id }}</div>
            <template v-if="setClass(item)">
              <div class="setbot">
                <i v-for="i in setClass(item)" :key="i" :class="i"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import timeUtil from './calendar';
import moment from 'moment';
export default {
  data() {
    return {
      arr: [],
      todayFLag: false,
      myDate: [],
      list: [],
      historyChose: [],
      dateTop: '',
      preDate: '',
      nextDate: '',
      taday: timeUtil.dateFormat(new Date()),
    };
  },
  props: {
    markDate: {
      type: Array,
      default: () => [],
    },
    markDateMore: {
      type: Array,
      default: () => [],
    },
    textTop: {
      type: Array,
      default: () => ['日', '一', '二', '三', '四', '五', '六'],
    },
    sundayStart: {
      type: Boolean,
      default: () => false,
    },
    agoDayHide: {
      type: String,
      default: `0`,
    },
    futureDayHide: {
      type: String,
      default: `2554387200`,
    },
    defaultChooseToday: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dateArea() {
      const startDay = this.list?.[0]?.date || '';
      const endDay = this.list?.[13]?.date || '';

      const format = new Date(endDay).getFullYear() - new Date(startDay).getFullYear() > 0 ? 'YYYY.MM.DD' : 'MM.DD';
      return moment(startDay).format('YYYY.MM.DD') + '-' + moment(endDay).format(format);
    },
  },

  created() {
    this.intStart();
    this.myDate = new Date();
  },
  methods: {
    toNowMonth() {
      this.todayFLag = true;
      this.ChoseMonth(timeUtil.dateFormat(new Date()));
    },
    intStart() {
      timeUtil.sundayStart = this.sundayStart;
    },
    setClass(data) {
      const obj = {};
      obj.markClassName = data.markClassName;
      return obj.markClassName;
    },
    clickDay(item) {
      this.taday = item.date;
      this.todayFLag = false;
      if (item.otherMonth === 'nowMonth' && !item.dayHide) {
        this.getList(this.myDate, '', item.date);
      }
    },
    ChoseMonth(date, isChosedDay = true) {
      this.taday = date;
      this.myDate = new Date(date);
      // this.$emit('choseDay',date);
      this.getList(this.myDate, '', date, isChosedDay);
    },
    PreMonth(date, isChosedDay = true) {
      this.todayFLag = false;
      date = timeUtil.dateFormat(date);
      this.myDate = new Date(this.list[0].date);
      if (isChosedDay) {
        this.getList(this.myDate, 'preMonth', date, isChosedDay);
      } else {
        this.getList(this.myDate, 'preMonth');
      }
    },
    NextMonth(date, isChosedDay = true) {
      this.todayFLag = false;
      date = timeUtil.dateFormat(date);

      this.myDate = new Date(this.list[13].date);
      if (isChosedDay) {
        this.getList(this.myDate, 'nextMonth', date, isChosedDay);
      } else {
        this.getList(this.myDate, 'nextMonth');
      }
    },
    forMatArgs() {
      let { markDate } = this;
      let { markDateMore } = this;
      markDate = markDate.map((k) => {
        return timeUtil.dateFormat(k);
      });
      markDateMore = markDateMore.map((k) => {
        k.date = timeUtil.dateFormat(k.date);
        return k;
      });
      return [markDate, markDateMore];
    },
    getList(date, type, chooseDay, isChosedDay = true) {
      const [markDate, markDateMore] = this.forMatArgs();
      this.dateTop = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}`;
      if (!chooseDay || this.todayFLag) {
        // 只有上翻下翻才重新渲染日历表,或者点击了今日按钮
        this.arr = timeUtil.getMonthList(this.myDate, type, chooseDay);
      }

      for (let i = 0; i < this.arr.length; i++) {
        let markClassName = '';
        const k = this.arr[i];
        k.chooseDay = false;
        const nowTime = k.date;
        const t = new Date(nowTime).getTime() / 1000;
        // 看每一天的class
        for (const c of markDateMore) {
          if (c.date === nowTime) {
            markClassName = c.className || '';
          }
        }
        // 标记选中某些天 设置class
        k.markClassName = markClassName;
        k.isMark = markDate.indexOf(nowTime) > -1;
        // 无法选中某天
        k.dayHide = t < this.agoDayHide || t > this.futureDayHide;
        if (k.isToday) {
          this.$emit('isToday', nowTime);
        }
        const flag = !k.dayHide && k.otherMonth === 'nowMonth';
        if (chooseDay && chooseDay === nowTime && flag) {
          this.$emit('choseDay', nowTime);
          this.historyChose.push(nowTime);
          k.chooseDay = true;
        } else if (this.historyChose[this.historyChose.length - 1] === nowTime && !chooseDay && flag) {
          k.chooseDay = true;
        }
      }

      this.list = this.arr;
    },
  },
  mounted() {
    this.getList(this.myDate);

    if (this.defaultChooseToday) {
      this.clickDay(this.list.find((item) => item.isToday));
    }

    this.$emit('initDate', this.list);
  },
  watch: {
    markDate: {
      handler(val, oldVal) {
        this.list = this.list.map((item) => {
          const v = val.map((i) => timeUtil.dateFormat(i));
          item.isMark = v.includes(item.date);
          return item;
        });
      },
      deep: true,
    },
    markDateMore: {
      handler(val, oldVal) {
        this.getList(this.myDate);
      },
      deep: true,
    },
    agoDayHide: {
      handler(val, oldVal) {
        this.getList(this.myDate);
      },
      deep: true,
    },
    futureDayHide: {
      handler(val, oldVal) {
        this.getList(this.myDate);
      },
      deep: true,
    },
    sundayStart: {
      handler(val, oldVal) {
        this.intStart();
        this.getList(this.myDate);
      },
      deep: true,
    },
    // 监听到变化后，回调当前日历区域
    list(val, oldVal) {
      const s = val?.[0]?.date || '';
      const e = val?.[13]?.date || '';

      const o_s = oldVal?.[0]?.date || '';
      const o_e = oldVal?.[13]?.date || '';

      // 不是因为日期区间变化而导致的更新则return
      if (s === o_s && e === o_e) return;

      this.$emit('dateAreaChange', [s, e]);
    },
  },
};
</script>
