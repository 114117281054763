<template>
  <div class="task-item" @click="hrefTask(data.id)">
    <div class="info-box">
      <div class="title-wrap">
            <h4 class="title">{{ data.name }}</h4>
            <a-icon type="right" style="font-size: 12px; color: #333" />

      </div>
      <div class="info">
        <span class="name"
          >{{ data.processVariables && data.processVariables.studentName }}({{
            data.processVariables && data.processVariables.studentCode
          }})</span
        >
        <span :class="['time', { warn: new Date(data.dueDate).getTime() < new Date().getTime() }]"
          >截止时间：{{ date }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      const yearFormat = new Date(this.data.dueDate).getFullYear() === new Date().getFullYear() ? '' : 'YYYY-';

      return moment(this.data.dueDate).format(`${yearFormat}MM-DD HH:mm`);
    },
  },
  methods: {
    hrefTask(taskId) {
      this.$router.push({path:'/mainHome',query:{taskId:taskId}})
    },
  },
};
</script>

<style lang="less" scoped>
.task-item {
  width: 100%;
  background: #fbfbfb;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 12px 12px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    &:hover{
    background: rgba(4, 203, 148, 0.06);
  }
  .info-box {
    flex: 1;
    width: 0;
    .title {
      font-size: 14px;
      color: #1f2d3d;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      color: rgba(31, 45, 61, 0.8);
    }
    .time {
      margin-left: 12px;
      &.warn {
        color: #ff7070;
      }
    }
  }
}
.title-wrap{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
</style>
