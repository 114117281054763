<template>
  <div class="calendar-box">
    <week-calendar :markDate="markDate" @dateAreaChange="dateAreaChange" @choseDay="handleChoseDay" />
    <a-spin :spinning="loading">
      <a-tabs :tabBarGutter="4" default-active-key="TASK" @change="changeActiveTab">
        <a-tab-pane key="TASK" :tab="taskCountTemp">
          <div class="count-box jc-sb items-center">
            <div class="items-center view-all" style="text-align: end">
              <span class="view-all-text" @click="hrefAllTask">查看全部</span>
              <a-icon type="right" style="font-size: 8px; color: #1f2d3d" />
            </div>
          </div>

          <div class="list">
            <scroll-list :loading="lowerLoading" :data-source="dataSource" :onLower="handleLower">
              <template slot="renderItem" slot-scope="item">
                <task-item :data="item.data" />
              </template>
            </scroll-list>
          </div>
        </a-tab-pane>
        <a-tab-pane key="STUDENT_SCHEDULE" :tab="scheduleCountTemp" scforce-render>
          <!-- <div class="count-box flex jc-sb items-center">
            <span class="count">共{{ dataSource.total }}条</span>
          </div> -->
          <div class="list">
            <scroll-list :loading="lowerLoading" :data-source="dataSource" :onLower="handleLower">
              <template slot="renderItem" slot-scope="item">
                <class-item :data="item.data" />
              </template>
            </scroll-list>
          </div>
        </a-tab-pane>
        <!-- <a-tab-pane key="STUDENT_BIRTHDAY" :tab="birthDayCountTemp">
          <div class="list">
            <scroll-list :loading="lowerLoading" :data-source="dataSource" :onLower="handleLower">
              <template slot="renderItem" slot-scope="item">
                <birthday-item :data="item.data" />
              </template>
            </scroll-list>
          </div>
        </a-tab-pane> -->
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';
import moment from 'moment';
import storage from 'store';
import WeekCalendar from './WeekCalendar';
import TaskItem from './TaskItem';
import ClassItem from './ClassItem';
import BirthdayItem from './BirthdayItem';
import ScrollList from './ScrollList';
import { getTodoDateListApi, getListByDateApi } from '@/api/home';

const defaultActive = 'TASK';

export default {
  directives: { infiniteScroll },
  components: { WeekCalendar, TaskItem, ClassItem, BirthdayItem, ScrollList },
  data() {
    return {
      activeType: defaultActive,
      dateArea: [],
      dataSource: {
        data: [],
        total: 0,
      },
      scheduleCountTemp: '学员课节',
      taskCountTemp: '待办任务',
      birthDayCountTemp: '学员生日',

      markDate: [],
      loading: false,
      lowerLoading: false,
      // 列表请求相关
      listQueryInfo: {
        type: defaultActive,
        currentDate: '',
        pageNum: 1,
        pageSize: 10,
        subject: storage.get('userInfo').subject,
      },
    };
  },
  computed: {
    dateMarksParams() {
      const [startDate, endDate] = this.dateArea;
      return { startDate, endDate, type: this.listQueryInfo.type, subject: this.listQueryInfo.subject };
    },
  },

  watch: {
    // 监听到list请求数据变化去发送请求
    listQueryInfo: {
      async handler(val) {
        // 如果是第一页，即不是触底加载，则展示loading蒙版,并且清空list
        // 否则展示触底的loading
        if (val.pageNum === 1) {
          this.loading = true;
          this.dataSource = { data: [], total: 0 };
        } else {
          this.lowerLoading = true;
        }

        const {
          data: { content = [], totalSize = 0 },
        } = await getListByDateApi(val);
        this.dataSource = {
          data: [...this.dataSource.data, ...content],
          total: totalSize,
        };
        if (this.activeType === 'TASK') {
          this.taskCountTemp = `待办任务(${this.dataSource.total})`;
        } else if (this.activeType === 'STUDENT_SCHEDULE') {
          this.scheduleCountTemp = `学员课节(${this.dataSource.total})`;
        } else if (this.activeType === 'STUDENT_BIRTHDAY') {
          this.birthDayCountTemp = `学员生日(${this.dataSource.total})`;
        }
        this.loading = false;
        this.lowerLoading = false;
      },
    },

    // 监听到日历区间和tab切换后去请求日期标记
    async dateMarksParams(val, oldVal) {
      if (!val.startDate || !val.endDate || JSON.stringify(val) === JSON.stringify(oldVal)) return;
      const { data } = await getTodoDateListApi(val);
      this.markDate = data.content || [];
    },
  },

  methods: {
    // 跳转全部任务
    hrefAllTask() {
      this.$router.push({ path: '/mainHome' });
    },
    // 当前日历起止日期
    async dateAreaChange(val = []) {
      console.log(val);
      const [startDate, endDate] = val.map((item) => moment(item).format('YYYY-MM-DD'));
      this.dateArea = [`${startDate} 00:00:00`, `${endDate} 23:59:59`];
    },
    // tab切换
    changeActiveTab(type) {
      this.activeType = type;
      this.listQueryInfo = { ...this.listQueryInfo, type, pageNum: 1 };
    },
    // 点击日期
    handleChoseDay(currentDate) {
      currentDate = moment(currentDate).format('YYYY-MM-DD');

      if (this.listQueryInfo.currentDate === currentDate) return;

      this.listQueryInfo = { ...this.listQueryInfo, currentDate, pageNum: 1 };
    },
    // 触底
    async handleLower() {
      this.listQueryInfo = { ...this.listQueryInfo, pageNum: this.listQueryInfo.pageNum + 1 };
    },
    // 获取list
    async handleGetData() {
      const { data } = await axios({
        url: fakeDataUrl,
        type: 'json',
        method: 'get',
        contentType: 'application/json',
      });
      this.dataSource = {
        data: data.results,
        total: 200,
      };
    },
  },
};
</script>

<style lang="less">
.calendar-box {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;
  }
  .jc-sb {
    justify-content: space-between;
  }
  .jc-fe {
    justify-content: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .wh_container {
    margin: 0;
  }

  .view-all {
    cursor: pointer;
  }

  .view-all-text {
    color: #1f2d3d;
    margin-right: 4px;
  }

  .count-box {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .count {
    color: #1f2d3d;
  }

  // 让list的高度自适应
  .ant-spin-nested-loading {
    flex: 1;
    height: 0;
    .ant-spin-container {
      height: 100%;
      .ant-tabs {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-tabs-content {
          flex: 1;
          height: 0;
          .ant-tabs-tabpane {
            display: flex;
            flex-direction: column;
            height: 100%;
            .list {
              flex: 1;
              height: 0;
            }
          }
        }
      }
    }
  }
}
</style>
