/**
 * 获取用户 UUID
 */
const getUserUUID = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
  const uuid = userInfo?.userID;
  if (uuid) {
    return uuid;
  }
  throw new Error('用户uuid 获取失败！');
};

export { getUserUUID };
