<template>
  <div class="data-report-wrapper">
    <div class="title">
      <span>我的数据简报</span>
      <span class="date">时间范围: {{ dataReportStartTime }} ~ {{ dataReportEndTime }}</span>
    </div>
    <div class="content">
      <div class="report-item" v-for="(item, idx) in report" :key="idx">
        <img :src="item.icon" class="icon" />
        <div class="report-title">{{ item.title }}</div>
        <ul class="data-list">
          <li class="data-item" v-for="dataItem in item.list" :key="dataItem.label">
            <span class="label">{{ dataItem.label }}</span>
            <span class="value hover-underline primary-color" @click="() => handleJump(dataItem)">{{
              dataItem.value == null ? '-' : dataItem.value
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import renewIcon from '../icons/renewIcon.svg';
import bushengIcon from '../icons/bushengIcon.svg';
import recommendIcon from '../icons/recommendIcon.svg';
import moment from 'moment';
import { getHomeDataReport } from '@/api/headTeacher';
import storage from 'store';
import { openNewTab } from '@/utils/util';

export default {
  data() {
    return {
      dataReportStartTime: null,
      dataReportEndTime: null,
      dataReport: {},
      userInfo: storage.get('userInfo'),
    };
  },

  computed: {
    report() {
      return [
        {
          title: '续费',
          icon: renewIcon,
          list: [
            {
              label: '全量',
              value: this.dataReport.renewAmount,
              jumpInfo: {
                path: '/orderIndex',
                query: { orderIds: JSON.stringify(this.dataReport.renewOrderIds) },
              },
            },
            {
              label: '考核',
              value: this.dataReport.renewAchievementAmount,
              jumpInfo: {
                path: '/orderIndex',
                query: { orderIds: JSON.stringify(this.dataReport.renewAchievementOrderIds) },
              },
            },
          ],
        },
        {
          title: '补升',
          icon: bushengIcon,
          list: [
            {
              label: '订单/单',
              value: this.dataReport.makeUpOrderNum,
              jumpInfo: {
                path: '/orderIndex',
                query: { orderIds: JSON.stringify(this.dataReport.makeUpOrderIds) },
              },
            },
            {
              label: '金额',
              value: this.dataReport.makeUpAmount,
              jumpInfo: {
                path: '/orderIndex',
                query: { orderIds: JSON.stringify(this.dataReport.makeUpOrderIds) },
              },
            },
          ],
        },
        {
          title: '推荐',
          icon: recommendIcon,
          list: [
            {
              label: '转介绍线索',
              value: this.dataReport.trackCount,
              jumpInfo: {
                path: '/referralIndex',
                query: { trackIds: JSON.stringify(this.dataReport.trackIds) },
              },
            },
            {
              label: '学员分享率',
              value: this.dataReport.shareRate,
              jumpInfo: {
                path: '/studentShareDetail',
                query: {
                  startTime: this.dataReportStartTime,
                  endTime: this.dataReportEndTime,
                  subject: this.userInfo.subject,
                  accountId: this.userInfo.userID,
                },
              },
            },
          ],
        },
      ];
    },
  },
  async created() {
    this.handleGetDataReport();
  },

  methods: {
    async handleGetDataReport() {
      // 固定查询当前月数据，获取当前月起止
      const startTime = moment().startOf('month').format('YYYY-MM-DD');
      const endTime = moment().endOf('month').format('YYYY-MM-DD');
      this.dataReportStartTime = startTime;
      this.dataReportEndTime = endTime;

      const { data } = await getHomeDataReport({
        endTime,
        startTime,
        classAdminIds: [this.userInfo.userID],
      });
      this.dataReport = data.content;
      console.log(this.dataReport);
    },
    handleJump(itemInfo) {
      if (!itemInfo.value || itemInfo.value == '0%') {
        return this.$message.warning('暂无数据');
      }
      const href = this.$router.resolve(itemInfo.jumpInfo).href;
      openNewTab(href);
    },
  },
};
</script>
<style scoped lang="less">
.data-report-wrapper {
  padding: 22px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    .date {
      color: #1a263366;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .content {
    display: flex;
    gap: 40px;
    padding: 26px 20px 20px;

    .report-item {
      background: #f7f8fa;
      position: relative;
      flex: 1;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .icon {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
      }
      .report-title {
        font-size: 14px;
        font-weight: 500;
        color: #1a2633;
        text-align: center;
        margin-top: 30px;
      }
      .data-list {
        // flex: 1;
        margin: 16px 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        .data-item {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 400;
          color: #1a2633;
          margin: 0 24px;
          padding: 12px 0;
          border-bottom: 1px solid rgba(31, 45, 61, 0.07);
          &:last-child {
            border-bottom: none;
          }
          .value {
            color: #00b281;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
